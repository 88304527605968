import { defineStore } from 'pinia';
import { store } from '/@/store';
import {
  AreaApi,
  getAllNames,
  getSalesList,
  getMerchandiserList,
  customerAll,
} from '/@/api/common';
import { SelectDataType } from '/@/api/common/model';
import { isArray } from '/@/utils/is';

interface ApiState {
  areaMap: Map<number | string, SelectDataType | Promise<SelectDataType>>;
  serviceSystemId: {
    id: number;
    name: string;
  }[];
  names: SelectDataType;
  salesId: SelectDataType;
  merchandiserId: SelectDataType;
  customerSelect: SelectDataType;
}

export const useApiStore = defineStore({
  id: 'app-api',
  state: (): ApiState => ({
    areaMap: new Map(),
    serviceSystemId: [],
    names: [],
    salesId: [],
    merchandiserId: [],
    customerSelect: [],
  }),
  actions: {
    // 获取省市区街道
    getArea(
      params: { areaId: number; level: number },
      option: { level: number; data: SelectDataType } = {} as any,
    ) {
      return new Promise((resolve: (list: SelectDataType) => void, reject) => {
        // 缓存里面存在就直接拿出来
        const id = params.areaId || 'provinceId';
        const data = this.areaMap.get(id);
        if (data) {
          if (isArray(data)) {
            if (params.level === option.level && option.data) {
              resolve([...option.data, ...data]);
            } else {
              resolve(data);
            }
          } else {
            data.then((res) => {
              if (params.level === option.level && option.data) {
                resolve([...option.data, ...res]);
              } else {
                resolve(res);
              }
            });
          }
          return;
        }
        const promise = AreaApi(params);
        // 将请求存进去
        this.areaMap.set(id, promise);
        promise
          .then((res) => {
            this.areaMap.set(id, res);
            if (params.level === option.level && option.data) {
              resolve([...option.data, ...res]);
            } else {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 获取客户名称下拉
    async getName() {
      if (this.names.length) {
        return this.names;
      }
      const res = await getAllNames();
      this.names.length = 0;
      this.names.push(...res);
      return res;
    },
    // 获取业务员
    async getSalesId() {
      if (this.salesId.length) {
        return this.salesId;
      }
      const res = await getSalesList();
      this.salesId.length = 0;
      this.salesId.push(...res);
      return res;
    },
    // 获取跟单员
    async getMerchandiserId() {
      if (this.merchandiserId.length) {
        return this.merchandiserId;
      }
      const res = await getMerchandiserList();
      this.merchandiserId.length = 0;
      this.merchandiserId.push(...res);
      return res;
    },
    // 获取下单客户下拉数据（根据当前登录账号权限返回
    async getCustomerSelect() {
      if (this.customerSelect.length) {
        return this.customerSelect;
      }
      const res = await customerAll();
      this.customerSelect.length = 0;
      this.customerSelect.push(...res);
      return res;
    },
    // 重新登录需要清空缓存
    clear() {
      this.areaMap.clear();
      this.serviceSystemId.length = 0;
      this.names.length = 0;
      this.salesId.length = 0;
      this.merchandiserId.length = 0;
      this.customerSelect.length = 0;
    },
  },
});

// Need to be used outside the setup
export function useApiStoreWithOut() {
  return useApiStore(store);
}
