import { RouterMap } from '../../types';
import { getOrderReminderCount } from '/@/api/sd/orderCommon';

export const routeMap: RouterMap = new Map();

routeMap.set('/kefu/order', {
  name: 'KefuOrder',
  meta: {
    nav: [
      {
        id: '6',
        title: '催单',
        sizeApi: () => {
          return getOrderReminderCount(110);
        },
      },
      { id: '1', title: '未完工', default: true },
      { id: '9', title: '待返件' },
      { id: '10', title: '待回访' },
      { id: '7', title: '审单退回' },
      { id: '5', title: '投诉' },
      { id: '8', title: '求助' },
      { id: '2', title: '已完成' },
      { id: '3', title: '已退单/已取消' },
      { id: '0', title: '所有' },
    ],
  },
});
routeMap.set('/kefu/cashback', {
  name: 'KefuCashback',
  meta: {
    nav: [
      { id: '0', title: '待处理' },
      { id: '20', title: '审核中' },
      { id: '60', title: '已付款' },
    ],
  },
});
