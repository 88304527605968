import { RouterMap } from '../../types';
import { dictToOption } from '/@/utils/dict';

export const routeMap: RouterMap = new Map();

routeMap.set('/baseConfigure/stationManagement', {
  name: 'StationManagement',
  meta: {
    nav: [
      { id: '10', title: '网点信息' },
      { id: '20', title: '临时网点' },
    ],
  },
});

routeMap.set('/baseConfigure/organization', {
  name: 'BaseConfigureOrganization',
});

routeMap.set('/baseConfigure/remoteRegion', {
  name: 'RemoteRegion',
});

routeMap.set('/baseConfigure/customerManagement', {
  name: 'customerManagement',
});

routeMap.set('/baseConfigure/kefuService', {
  name: 'KefuService',
  meta: {
    nav: [{ id: '10', title: '人员' }],
  },
});

routeMap.set('/baseConfigure/payAbnormal', {
  name: 'BaseConfigurePayAbnormal',
  meta: {
    nav: [
      { id: '50', title: '暂时无法付款' },
      { id: '30', title: '异常待付款' },
    ],
  },
});

routeMap.set('/baseConfigure/customerStandard', {
  name: 'CustomerStandard',
  meta: {
    nav: [
      { id: '10', title: '规范组' },
      { id: '20', title: '完工组' },
      { id: '30', title: '质保判断' },
    ],
  },
});

routeMap.set('/baseConfigure/customerPrice', {
  name: 'CustomerPrice',
  meta: {
    api: () => {
      return Promise.resolve([
        { id: 0, title: '工单费用' },
        ...dictToOption('FeeItem', 'title', 'id').filter((item) => item.value3 == '1'),
      ]);
    },
  },
});

routeMap.set('/baseConfigure/shop', {
  name: 'Shop',
});
