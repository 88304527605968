import type { RouteLocationNormalized, RouteRecordNormalized } from 'vue-router';
import type { App, Plugin } from 'vue';

import { unref } from 'vue';
import { isArray, isFunction, isObject } from '/@/utils/is';
import { find } from 'lodash';

export const noop = () => {};

/**
 * @description:  Set ui mount node
 */
export function getPopupContainer(node?: HTMLElement): HTMLElement {
  return (node?.parentNode as HTMLElement) ?? document.body;
}

/**
 * Add the object as a parameter to the URL
 * @param baseUrl url
 * @param obj
 * @returns {string}
 * eg:
 *  let obj = {a: '3', b: '4'}
 *  setObjToUrlParams('www.baidu.com', obj)
 *  ==>www.baidu.com?a=3&b=4
 */
export function setObjToUrlParams(baseUrl: string, obj: any): string {
  let parameters = '';
  for (const key in obj) {
    parameters += key + '=' + encodeURIComponent(obj[key]) + '&';
  }
  parameters = parameters.replace(/&$/, '');
  return /\?$/.test(baseUrl) ? baseUrl + parameters : baseUrl.replace(/\/?$/, '?') + parameters;
}

// 深度合并
export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
  let key: string;
  for (key in target) {
    src[key] = isObject(src[key]) ? deepMerge(src[key], target[key]) : (src[key] = target[key]);
  }
  return src;
}

export function openWindow(
  url: string,
  opt?: { target?: TargetContext | string; noopener?: boolean; noreferrer?: boolean },
) {
  const { target = '__blank', noopener = true, noreferrer = true } = opt || {};
  const feature: string[] = [];

  noopener && feature.push('noopener=yes');
  noreferrer && feature.push('noreferrer=yes');

  window.open(url, target, feature.join(','));
}

// dynamic use hook props
export function getDynamicProps<T, U>(props: T): Partial<U> {
  const ret: Recordable = {};

  Object.keys(props).map((key) => {
    ret[key] = unref((props as Recordable)[key]);
  });

  return ret as Partial<U>;
}

export function getRawRoute(route: RouteLocationNormalized): RouteLocationNormalized {
  if (!route) return route;
  const { matched, ...opt } = route;
  return {
    ...opt,
    matched: (matched
      ? matched.map((item) => ({
          meta: item.meta,
          name: item.name,
          path: item.path,
        }))
      : undefined) as RouteRecordNormalized[],
  };
}

export const withInstall = <T>(component: T, alias?: string) => {
  const comp = component as any;
  comp.install = (app: App) => {
    app.component(comp.name || comp.displayName, component);
    if (alias) {
      app.config.globalProperties[alias] = component;
    }
  };
  return component as T & Plugin;
};

// 清除对象中的空值
export const clearEmpty = (obj: any) => {
  return Object.keys(obj).reduce((o, key) => {
    if (isObject(obj[key]) || isArray(obj[key])) {
      if (Object.keys(obj[key]).length > 0) {
        obj[key] = clearEmpty(obj[key]);
      } else {
        obj[key] = undefined;
      }
    }
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '' && !isFunction(obj[key])) {
      o[key] = obj[key];
    }
    return o;
  }, new obj.constructor() as { [key: string]: any });
};

// 增加%
export const addPercentage = (number: number) => {
  return (number * 100).toFixed(2) + '%';
};

export const parsePercentageString = (str: string) => {
  // 判断字符串中是否含有百分号
  if (!/%$/.test(str)) {
    return 0;
  }
  // 去掉字符串中的百分号
  const cleanedStr = str.replace('%', '');
  // 将字符串转换为数字，并除以 100 得到百分数
  const percentage = parseFloat(cleanedStr) / 100;
  return percentage;
};

export const percentageToNumber = (str) => {
  const num = parseFloat(str.replace('%', '')) / 100;
  return isNaN(num) ? 0 : num;
};

export const checkPositivePercentage = (str) => {
  // 传入百分比 大于0 返回triangle-up 小于0 返回triangle-down  0和非法参数 返回''
  if (typeof str === 'number') {
    return '';
  } else if (typeof str === 'string' && str.endsWith('%')) {
    const value = parseFloat(str);
    if (!isNaN(value)) {
      if (value === 0) {
        return '';
      } else {
        return value > 0 ? 'triangle-up' : 'triangle-down';
      }
    }
  }
  return '';
};

export const convertStringToNumber = (str) => {
  const number = Number(str);

  if (isNaN(number) || number === 0) {
    return '';
  } else if (number > 0) {
    return 'triangle-up';
  } else {
    return 'triangle-down';
  }
};

// 将20230911 转为 09-11
export const formatDate = (dateStr: string): string => {
  const month = dateStr.substring(4, 6);
  const day = dateStr.substring(6, 8);
  return `${month}-${day}`;
};

// 来获取当前月份的日期列表。
export const generateCurrentMonthDates = (): string[] => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // 补零
  const endDate = new Date(year, today.getMonth() + 1, 0).getDate(); // 获取当前月份的最后一天
  const dates: string[] = [];

  for (let i = 1; i <= endDate; i++) {
    const day = String(i).padStart(2, '0'); // 补零
    dates.push(`${month}-${day}`);
  }
  return dates;
};

// 得到options中label
export const getValueLabel = (data, value) => {
  const result = find(data, { value });
  return result ? result.label : null;
};

// 全局拼接图片
export const interceptorImgUrl = (url: string) => {
  if (!url) return '';
  const prefix = import.meta.env.VITE_IMG_PREFIX;
  if (url.includes(prefix)) {
    const paths = interceptorImgUrl(url.replace(prefix, '')).split('/');
    return `https://${paths[1]}.oss-cn-shenzhen.aliyuncs.com/${paths.slice(2).join('/')}`;
  }
  return url;
};
