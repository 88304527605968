import type { UserInfo } from '/#/store';
import type { ErrorMessageMode } from '/#/axios';
import { defineStore } from 'pinia';
import { store } from '/@/store';
import { RoleEnum } from '/@/enums/roleEnum';
import { PageEnum } from '/@/enums/pageEnum';
import {
  ROLES_KEY,
  TOKEN_KEY,
  USER_INFO_KEY,
  SECRET_KEY,
  REFRESH_TOKEN,
  BUSINESS_KEY,
} from '/@/enums/cacheEnum';
import { getAuthCache, setAuthCache } from '/@/utils/auth';
import { GetUserInfoModel, LoginParams } from '/@/api/sys/model/userModel';
import { doLogout, getUserInfo, loginApi, switchBz } from '/@/api/sys/user';
import { useMessage } from '/@/hooks/web/useMessage';
import { router } from '/@/router';
import { usePermissionStore } from '/@/store/modules/permission';
import { RouteRecordRaw } from 'vue-router';
import { PAGE_NOT_FOUND_ROUTE } from '/@/router/routes/basic';
import { h } from 'vue';
import { fetchDictionary } from '/@/utils/dict';
import { usePhoneStoreWithOut } from './phone';
import { PageType } from '/@/myComponents/OrderDetail/type';
import { useWatermark } from '/@/hooks/web/useWatermark';
import { passwordCacheKey, passwordReg } from '/@/layouts/default/header/components/ChangePassword';
import { Decrypt } from '/@/utils/crypto';
import { useApiStoreWithOut } from './api';

interface UserState {
  userInfo: Nullable<UserInfo>;
  token?: string;
  refresh_token?: string;
  business: number[];
  secret_key?: string;
  roleList: RoleEnum[];
  sessionTimeout?: boolean;
  lastUpdateTime: number;
  pageType: PageType;
}

const watermark = useWatermark();
export const useUserStore = defineStore({
  id: 'app-user',
  state: (): UserState => ({
    // user info
    userInfo: null,
    // token
    token: undefined,
    // refresh_token
    refresh_token: undefined,
    // 事业部
    business: [],
    // 加密key
    secret_key: undefined,
    // roleList
    roleList: [],
    // Whether the login expired
    sessionTimeout: false,
    // Last fetch time
    lastUpdateTime: 0,
    pageType: undefined as unknown as PageType,
  }),
  getters: {
    getUserInfo(): UserInfo {
      return this.userInfo || getAuthCache<UserInfo>(USER_INFO_KEY) || {};
    },
    getToken(): string {
      return this.token || getAuthCache<string>(TOKEN_KEY);
    },
    getRefreshToken(): string {
      return this.refresh_token || getAuthCache<string>(REFRESH_TOKEN);
    },
    getBusiness(): number[] {
      return this.business.length ? this.business : getAuthCache<number[]>(BUSINESS_KEY);
    },
    getSecretKey(): string {
      return this.secret_key || getAuthCache<string>(SECRET_KEY);
    },
    getRoleList(): RoleEnum[] {
      return this.roleList.length > 0 ? this.roleList : getAuthCache<RoleEnum[]>(ROLES_KEY);
    },
    getSessionTimeout(): boolean {
      return !!this.sessionTimeout;
    },
    getLastUpdateTime(): number {
      return this.lastUpdateTime;
    },
    getPageType(): PageType {
      return this.pageType;
    },
  },
  actions: {
    setToken(info: string | undefined) {
      this.token = info ? info : ''; // for null or undefined value
      setAuthCache(TOKEN_KEY, info);
    },
    setRefreshToken(info: string | undefined) {
      this.refresh_token = info ? info : ''; // for null or undefined value
      setAuthCache(REFRESH_TOKEN, info);
    },
    setBusiness(value: number[]) {
      this.business = value;
      setAuthCache(BUSINESS_KEY, value);
    },
    setSecretKey(key: string | undefined) {
      this.secret_key = key ? key : '';
      setAuthCache(SECRET_KEY, key);
    },
    setRoleList(roleList: RoleEnum[]) {
      this.roleList = roleList;
      setAuthCache(ROLES_KEY, roleList);
    },
    setUserInfo(info: UserInfo | null) {
      this.userInfo = info;
      this.lastUpdateTime = new Date().getTime();
      setAuthCache(USER_INFO_KEY, info);
    },
    setPageType(type: PageType) {
      this.pageType = type;
    },
    setSessionTimeout(flag: boolean) {
      this.sessionTimeout = flag;
    },
    resetState() {
      this.userInfo = null;
      this.token = '';
      this.roleList = [];
      this.sessionTimeout = false;
      localStorage.removeItem(passwordCacheKey);
    },
    /**
     * @description: login
     */
    async login(
      params: LoginParams & {
        goHome?: boolean;
        mode?: ErrorMessageMode;
      },
    ): Promise<number[]> {
      try {
        const { mode, ...loginParams } = params;
        // 取出加密前的密码
        const password = loginParams.password;
        const data = await loginApi(loginParams, mode);
        const { access_token, secret_key, refresh_token } = data;
        const { need_change_pwd, business_types } = Decrypt(secret_key) as {
          need_change_pwd: boolean;
          business_types: string;
        };
        // 密码复杂度是否需要强制修改
        const force = passwordReg.test(password) ? '0' : '1';
        localStorage.setItem(passwordCacheKey, need_change_pwd ? '2' : force);

        this.setToken(access_token);
        this.setRefreshToken(refresh_token);
        this.setSecretKey(secret_key);
        const business = business_types
          .split(',')
          .filter((item) => item)
          .map(Number);
        this.setBusiness(business);
        return business;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async switchBz(business_type: number) {
      const data = await switchBz({
        client_info: import.meta.env.VITE_BASE_AUTHORIZATION,
        refresh_token: this.getRefreshToken,
        business_type,
      });
      const { access_token, secret_key, refresh_token } = data;
      this.setToken(access_token);
      this.setRefreshToken(refresh_token);
      this.setSecretKey(secret_key);
      return await this.afterLoginAction();
    },
    async afterLoginAction(): Promise<GetUserInfoModel | null> {
      if (!this.getToken) return null;
      // get user info
      const userInfo = await this.getUserInfoAction();
      const sessionTimeout = this.sessionTimeout;
      if (sessionTimeout) {
        this.setSessionTimeout(false);
      } else {
        const permissionStore = usePermissionStore();
        if (!permissionStore.isDynamicAddedRoute) {
          const routes = await permissionStore.buildRoutesAction();
          routes.forEach((route) => {
            router.addRoute(route as unknown as RouteRecordRaw);
          });
          router.addRoute(PAGE_NOT_FOUND_ROUTE as unknown as RouteRecordRaw);
          permissionStore.setDynamicAddedRoute(true);
        }
        // 登录页
        if (location.pathname.includes('login')) {
          await router.replace(userInfo?.homePath || PageEnum.BASE_HOME);
        }
        // 其他页面刷新
        else {
          router.go(0);
        }
      }
      return userInfo;
    },
    async getUserInfoAction(): Promise<UserInfo | null> {
      if (!this.getToken) return null;
      const userInfo = await getUserInfo();
      if (!userInfo.businessType) {
        this.setToken(undefined);
        this.logout();
        return userInfo;
      }
      await fetchDictionary();
      this.setUserInfo(userInfo);
      watermark.setWatermark(`${userInfo.name || ''}${userInfo.username || ''}`);
      // 每次登录成功后初始化电话
      const phoneStore = usePhoneStoreWithOut();
      phoneStore.init();
      // 清空缓存的下拉
      useApiStoreWithOut().clear();
      // 设置快捷键返回工作台
      removeEventListener('keydown', this.shortcutKeyHome);
      addEventListener('keydown', this.shortcutKeyHome);
      return userInfo;
    },
    /**
     * @description: logout
     */
    async logout(goLogin = false) {
      if (this.getToken) {
        try {
          await doLogout();
        } catch {
          console.log('注销Token失败');
        }
      }
      this.setToken(undefined);
      this.setSecretKey(undefined);
      this.setSessionTimeout(false);
      this.setUserInfo(null);
      watermark.clear();
      const phoneStore = usePhoneStoreWithOut();
      phoneStore.getPhone?.disconnect(); // 断开链接
      goLogin && router.push(PageEnum.BASE_LOGIN);
    },

    /**
     * @description: Confirm before logging out
     */
    confirmLoginOut() {
      const { createQuestionModal } = useMessage();
      createQuestionModal({
        title: () => h('span', '提示'),
        content: () => h('span', '确定要退出当前账号吗？'),
        onOk: async () => {
          await this.logout(true);
        },
      });
    },
    // 快捷键返回工作台
    shortcutKeyHome(event) {
      // ctrl + q 返回工作台
      if (event.ctrlKey && event.code === 'KeyQ') {
        router.push(PageEnum.BASE_HOME);
      }
    },
  },
});

// Need to be used outside the setup
export function useUserStoreWithOut() {
  return useUserStore(store);
}
