import {
  AnalysisResultModel,
  GangedSelectResultModel,
  GetAmountByCustomerIdResultModel,
  GetTimelinessRuleParams,
  GetTimelinessRuleResultModel,
  ProductTreeResultModel,
  SelectDataType,
  UrgentRuleByCustomerIdParams,
  UrgentRuleByCustomerIdResultModel,
  GetCustomerByNameCodeParams,
  GetServiceTypeAndWarrantyResultModel,
  GetAllCustomerWithIdAndNameResultModel,
  getProductCategoryTreeResultModel,
  GetAllProductCategory1stWithIdAndNameResultModel,
  GetCustomerFormResultModel,
  UploadValueEnum,
  GetProductCategory2ndNodeResultModel,
  GetAllDeptTreeResultModel,
  GetUserTitleResultModel,
  GetSpSearchParams,
  GetSpSearchResultModel,
  GetOrderTypeListResultModel,
  GetBusinessTypeTreeResultModel,
  CheckRepeatOrderParams,
  CheckRepeatOrderResultModel,
  DownloadPdfParams,
} from './model';
import { mdHttp, sdHttp } from '/@/utils/http/axios';

export enum CommonApi {
  Area = '/common/region/getRegionById',
  Upload = '/comment/file/upload',
  Name = '/common/customer/getAllCustomerWithIdAndName',
  GetAllCustomerWithIdAndNameByBusinessType = '/common/customer/getAllCustomerWithIdAndNameByBusinessType',
  Street = '/common/region/getTownNodeList/',
  NetworkName = '/common/servicePoint/getAllServicePointWithIdAndName',
  GetAllServicePointWithIdAndNameByBusinessType = '/common/servicePoint/getAllServicePointWithIdAndNameByBusinessType',
  CustomerAll = '/common/order/getCustomerAll',
  ShopByCustomerId = '/common/order/getShopByCustomerId',
  ProductTree = '/common/order/getProductTree',
  UrgentRuleByCustomerId = '/common/order/getUrgentRuleByCustomerId',
  Analysis = '/common/area/analysis',
  GetTimelinessRule = '/common/order/getTimelinessRule',
  GetAmountByCustomerId = '/common/order/getAmountByCustomerId',
  GetAllServiceSystemWithIdAndName = '/common/serviceSystem/getAllServiceSystemWithIdAndName',
  GetSalesList = '/common/userTitle/getSalesList',
  GetMerchandiserList = '/common/userTitle/getMerchandiserList',
  GetCustomerByNameCode = '/common/customer/getCustomerByNameCode',
  GetMyCustomerList = '/common/customer/getMyCustomerList',
  GetByCodeRoName = '/common/servicePoint/getByCodeRoName',
  DownloadTemplate = '/comment/file/downloadTemplate/',
  GangedSelect = '/common/serviceSystem/gangedSelect',
  GetServiceTypeAndWarranty = '/common/serviceTypeTag/getServiceTypeAndWarranty',
  GetAllCustomerWithIdAndName = '/common/customer/getAllCustomerWithIdAndName',
  GetProductCategoryTree = '/pf/autoGrade/getProductCategoryTree',
  GetCustomerBankToPrivate = '/common/customer/getCustomerBankToPrivate',
  GetAllProductCategory1stWithIdAndName = '/common/productCategory/getAllProductCategory1stWithIdAndNameByBusinessType',
  GetCustomerForm = '/common/order/getCustomerForm',
  GetProductCategory2ndNode = '/common/productCategory/getProductCategory2ndNode',
  GetAllDeptTree = '/dept/getAllDeptTree',
  GetUserTitle = '/userTitle/getTag',
  GetSpSearch = '/common/servicePoint/getSpSearch',
  GetKefuByNameOrCode = '/common/user/byIdName',
  GetOrderTypeList = '/common/serviceTypeTag/getOrderTypeList',
  GetBusinessTypeTree = '/pf/product/getBusinessTypeTree',
  CheckRepeatOrder = '/order/checkRepeatOrder',
  GetProductCategory2ndNodeByCt = '/common/productCategory/getProductCategory2ndNodeByCt/',
  GetProductByCategory2ndAndCt = '/common/productCategory/getProductByCategory2ndAndCt/',
  getAllCustomerIdName = '/common/customer/getAllCustomerIdName',
  DownloadPdf = '/comment/file/downFile',
  getSalesOrMerOrManageList = '/common/userTitle/getSalesOrMerOrManageList',
  CreateCtProductGetExcelTemplate = '/comment/file/downloadTemplate/5',
  getProductCategory2ndNodeByBusinessType = '/common/productCategory/getProductCategory2ndNodeByBusinessType',
}

// 公共方法数据转为label value格式
export function toOptions(data: SelectDataType) {
  data.forEach((item) => {
    item.label = item.name;
    item.value = item.id;
  });
}

// 查询区域数据，默认不传取省份信息
export async function AreaApi(params: { areaId: number; level: number }) {
  const res = await mdHttp.get<SelectDataType>(
    {
      url: CommonApi.Area,
      params,
    },
    {
      hideLoading: true,
    },
  );
  res.forEach((item) => {
    item.label = item.name;
    item.value = item.id;
    item.isLeaf = params.level === 3;
  });
  return res;
}

// 通用上传
export function upload(data: FormData, value: UploadValueEnum) {
  return mdHttp.post<string>(
    {
      url: CommonApi.Upload,
      data,
      headers: {
        value,
      },
    },
    {
      hideLoading: true,
    },
  );
}

// 查询所有客户
export async function getAllNames(params?: { ids: number[] }) {
  const res = await mdHttp.get<SelectDataType>({
    url: CommonApi.Name,
    params,
  });
  // 格式化客户等级
  toOptions(res);
  return res;
}

// 查询当前事业部下所有客户
export async function getAllCustomerWithIdAndNameByBusinessType(params?: { ids: number[] }) {
  const res = await mdHttp.get<SelectDataType>({
    url: CommonApi.GetAllCustomerWithIdAndNameByBusinessType,
    params,
  });
  // 格式化客户等级
  toOptions(res);
  return res;
}

// 通过区域id获取所有街道
export async function StreetApi(params: { districtId: number }) {
  const res = await mdHttp.get<SelectDataType>(
    {
      url: CommonApi.Street,
      params,
    },
    {
      hideLoading: true,
    },
  );
  return res;
}

// 通过区域id获取所有街道

function getBeforeAndAfterLastSlash(str) {
  const lastSlashIndex = str.lastIndexOf('/');

  if (lastSlashIndex === -1) {
    return [str, ''];
  }

  const before = str.substring(0, lastSlashIndex);
  const after = str.substring(lastSlashIndex + 1);

  return [before, after];
}

export async function getTownNodeList(params: any) {
  const [after] = getBeforeAndAfterLastSlash(params.id);
  params = { districtId: after };
  const res = await mdHttp.get<SelectDataType>(
    {
      url: CommonApi.Street,
      params,
    },
    {
      hideLoading: true,
    },
  );
  return res;
}

// 业务员
export async function getSalesList() {
  const res = await mdHttp.get<SelectDataType>({
    url: CommonApi.GetSalesList,
  });
  // 格式化客户等级
  toOptions(res);
  return res;
}

// 业务员and业务主管
export async function getSalesOrMerOrManageList(params: { type: string }) {
  const res = await mdHttp.get<SelectDataType>(
    {
      url: CommonApi.getSalesOrMerOrManageList,
      params,
    },
    {
      hideLoading: true,
    },
  );
  // 格式化客户等级
  toOptions(res);
  return res;
}

// 跟单员
export async function getMerchandiserList() {
  const res = await mdHttp.get<SelectDataType>({
    url: CommonApi.GetMerchandiserList,
  });
  // 格式化客户等级
  toOptions(res);
  return res;
}

// 查询所有网点名称
export async function networkName() {
  const res = await mdHttp.get<SelectDataType>({
    url: CommonApi.NetworkName,
  });
  // 格式化客户等级
  toOptions(res);
  return res;
}

// 查询当前事业部下所有网点名称
export async function getAllServicePointWithIdAndNameByBusinessType() {
  const res = await mdHttp.get<SelectDataType>({
    url: CommonApi.GetAllServicePointWithIdAndNameByBusinessType,
  });
  // 格式化客户等级
  toOptions(res);
  return res;
}

// 工单的客户下拉数据
export async function customerAll() {
  const res = await mdHttp.get<(SelectDataType[0] & { code: string })[]>({
    url: CommonApi.CustomerAll,
  });
  // 格式化客户等级
  toOptions(res);
  return res;
}

// 根据客户ID查询下单店铺
export async function shopByCustomerId(customerId: number) {
  const res = await mdHttp.get<SelectDataType>({
    url: CommonApi.ShopByCustomerId,
    params: { customerId },
  });
  // 格式化客户等级
  toOptions(res);
  return res;
}

// 获取产品分类与产品树
export function productTree(customerId: number) {
  return mdHttp.get<ProductTreeResultModel>({
    url: CommonApi.ProductTree,
    params: { customerId },
  });
}

// 根据客户ID和一级产品分类ID查询加急奖励规则
export function urgentRuleByCustomerId(params: UrgentRuleByCustomerIdParams) {
  return mdHttp.get<UrgentRuleByCustomerIdResultModel>({
    url: CommonApi.UrgentRuleByCustomerId,
    params,
  });
}

// 根据一级产品分类ID和二级区域ID查询满足的时效活动
export function getTimelinessRule(params: GetTimelinessRuleParams) {
  return mdHttp.get<GetTimelinessRuleResultModel>({
    url: CommonApi.GetTimelinessRule,
    params,
  });
}

// 地址解析接口
export function analysis(fullAddress: string) {
  return mdHttp.post<AnalysisResultModel>(
    {
      url: CommonApi.Analysis,
      data: { fullAddress },
    },
    { errorMessageMode: 'none' },
  );
}

// 12.下单根据手机号 + 分机号 + 客户检查是否重单,
export function checkRepeatOrder(params: CheckRepeatOrderParams) {
  return sdHttp.get<CheckRepeatOrderResultModel>({
    url: CommonApi.CheckRepeatOrder,
    params,
  });
}

// 工单的客户金额数据
export function getAmountByCustomerId(customerId: number) {
  return mdHttp.get<GetAmountByCustomerIdResultModel>({
    url: CommonApi.GetAmountByCustomerId,
    params: { customerId },
  });
}

// 查询所有服务体系
export async function GetAllServiceSystemWithIdAndName() {
  const res = await mdHttp.get<SelectDataType>(
    {
      url: CommonApi.GetAllServiceSystemWithIdAndName,
    },
    {
      hideLoading: true,
      cache: true,
    },
  );
  toOptions(res);
  return res;
}

// 根据名称和编码查询所有的客户(id、name,code),
export async function getCustomerByNameCode(params: GetCustomerByNameCodeParams) {
  const res = await mdHttp.get<SelectDataType>(
    {
      url: CommonApi.GetCustomerByNameCode,
      params,
    },
    {
      hideLoading: true,
    },
  );
  toOptions(res);
  return res;
}

// 根据名称和编码查询客户(id、name,code),
export async function getMyCustomerList(params: GetCustomerByNameCodeParams) {
  const res = await mdHttp.get<SelectDataType>(
    {
      url: CommonApi.GetMyCustomerList,
      params,
    },
    {
      hideLoading: true,
    },
  );
  toOptions(res);
  return res;
}

// 根据网点编码或者网点名称获取查找网点,
export async function getByCodeRoName(params: { nameCode: number }) {
  const res = await mdHttp.get<SelectDataType>(
    {
      url: CommonApi.GetByCodeRoName,
      params: { codeName: params.nameCode },
    },
    {
      hideLoading: true,
    },
  );
  toOptions(res);
  return res;
}

// 文件模板下载
export function downloadTemplate(value: number) {
  return mdHttp.get<string>({
    url: CommonApi.DownloadTemplate + value,
  });
}

export function CreateCtProductGetExcelTemplate() {
  return mdHttp.get<string>({
    url: CommonApi.CreateCtProductGetExcelTemplate,
  });
}

// 服务体系（id,name）、服务规则(id,name)、价格轮次(value,name)级联选择,
export function gangedSelect() {
  return mdHttp.get<GangedSelectResultModel>(
    {
      url: CommonApi.GangedSelect,
    },
    {
      hideLoading: true,
    },
  );
}

// 服务类型、质保类型
export function getServiceTypeAndWarranty() {
  return mdHttp.get<GetServiceTypeAndWarrantyResultModel>({
    url: CommonApi.GetServiceTypeAndWarranty,
  });
}

// 查询客户
export function getAllCustomerWithIdAndName() {
  return mdHttp.get<GetAllCustomerWithIdAndNameResultModel[]>({
    url: CommonApi.GetAllCustomerWithIdAndName,
  });
}

// 获取所有产品分类下的产品
export function getProductCategoryTree() {
  return mdHttp.get<getProductCategoryTreeResultModel>({
    url: CommonApi.GetProductCategoryTree,
  });
}

// 获取对私的客户返回(id、name),用户业务跟单重新获取客户下拉
export async function getCustomerBankToPrivate() {
  const res = await mdHttp.get<SelectDataType>({
    url: CommonApi.GetCustomerBankToPrivate,
  });
  // 格式化客户等级
  toOptions(res);
  return res;
}

// 获取所有产品一级分类
export function getAllProductCategory1stWithIdAndName() {
  return mdHttp.get<GetAllProductCategory1stWithIdAndNameResultModel>({
    url: CommonApi.GetAllProductCategory1stWithIdAndName,
  });
}

// 获取二级产品
export function getProductCategory2ndNode() {
  return mdHttp.get<GetProductCategory2ndNodeResultModel[]>({
    url: CommonApi.GetProductCategory2ndNode,
  });
}

// 获取二级产品 区分事业部
export function getProductCategory2ndNodeByBusinessType() {
  return mdHttp.get<GetProductCategory2ndNodeResultModel[]>({
    url: CommonApi.getProductCategory2ndNodeByBusinessType,
  });
}

// 客户下单获取余额店铺(客户系统下单专用),
export async function getCustomerForm() {
  const res = await mdHttp.get<GetCustomerFormResultModel>({
    url: CommonApi.GetCustomerForm,
  });
  toOptions(res.shopList);
  return res;
}

// 1.查询所有客户
export function getAllCustomerIdName() {
  return mdHttp.get<{ id: number; name: string; value: string }[]>({
    url: CommonApi.getAllCustomerIdName,
  });
}

// 查询所有内部部门接口
export function getAllDeptTree() {
  return mdHttp.get<GetAllDeptTreeResultModel>({
    url: CommonApi.GetAllDeptTree,
  });
}

// 职位下拉
export function getUserTitle(systemId = 1) {
  return mdHttp.get<GetUserTitleResultModel>({
    url: CommonApi.GetUserTitle,
    params: { systemId },
  });
}

// 4.根据手机号/网点编码/网点名称查询网点信息,
export function getSpSearch(params: GetSpSearchParams) {
  return mdHttp.get<GetSpSearchResultModel>({
    url: CommonApi.GetSpSearch,
    params,
  });
}

// 根据名称和编码查询客服,
export async function getKefuByNameOrCode(params: { name: string }) {
  const res = await mdHttp.get<SelectDataType>(
    {
      url: CommonApi.GetKefuByNameOrCode,
      params,
    },
    {
      hideLoading: true,
    },
  );
  toOptions(res);
  return res;
}

// 工单类型-服务项目 级联查询条件
export function getOrderTypeList() {
  return mdHttp.get<GetOrderTypeListResultModel>({
    url: CommonApi.GetOrderTypeList,
  });
}

// 获取产品分类与产品树(事业部),
export function getBusinessTypeTree() {
  return mdHttp.get<GetBusinessTypeTreeResultModel>({
    url: CommonApi.GetBusinessTypeTree,
  });
}

// 根据客户id获取一二级产品
export function getProductCategory2ndNodeByCt(customerId: number) {
  return mdHttp.get<GetProductCategory2ndNodeResultModel[]>({
    url: CommonApi.GetProductCategory2ndNodeByCt + customerId,
  });
}

// 根据客户id和二级产品id获取产品
export function getProductByCategory2ndAndCt(params: {
  customerId: number | string;
  categoryId?: number | string;
}) {
  return mdHttp.get<GetAllProductCategory1stWithIdAndNameResultModel>({
    url: CommonApi.GetProductByCategory2ndAndCt + params.customerId + '/' + params.categoryId,
  });
}

// pdf下载
export function downloadPdf(params: DownloadPdfParams) {
  return mdHttp.get<any>(
    {
      url: CommonApi.DownloadPdf,
      params,
      responseType: 'blob',
    },
    { isReturnNativeResponse: true },
  );
}
