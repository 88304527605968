import dayjs from 'dayjs';
import { isArray, isObject, isString } from '/@/utils/is';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export function joinTimestamp<T extends boolean>(
  join: boolean,
  restful: T,
): T extends true ? string : object;

export function joinTimestamp(join: boolean, restful = false): string | object {
  if (!join) {
    return restful ? '' : {};
  }
  const now = new Date().getTime();
  if (restful) {
    return `?_t=${now}`;
  }
  return { _t: now };
}

/**
 * @description: Format request parameter time
 */
export function formatRequestDate(params: Recordable) {
  if (Object.prototype.toString.call(params) !== '[object Object]') {
    return;
  }
  for (const key in params) {
    const format = params[key]?.format ?? null;
    if (format && typeof format === 'function') {
      params[key] = params[key].format(DATE_TIME_FORMAT);
    }
    if (isString(key)) {
      const value = params[key];
      if (value) {
        try {
          params[key] = value;
          if (isString(value)) {
            params[key] = value.trim();
            const format = [
              'YYYY-MM-DD',
              'YYYY-MM-DD HH:mm:ss',
              'YYYY-MM-DD HH:mm',
              'YYYY-MM-DD HH',
            ];
            try {
              if (value.split('-')[0].length === 4) {
                params[key] =
                  dayjs(value.trim(), format, true).isValid() && /\D/.test(value.trim())
                    ? +dayjs(value.trim(), format, true)
                    : value.trim();
              }
            } catch (e) {}
          }
          // 针对大数字处理
          if (isObject(value) && value._isBigNumber) {
            params[key] = value.toString();
          }
        } catch (error: any) {
          throw new Error(error);
        }
      }
      if (isArray(params[key])) {
        params[key].forEach((item, index) => {
          // 针对大数字处理
          if (isObject(item) && item._isBigNumber) {
            params[key][index] = item.toString();
          }
          formatRequestDate(item);
        });
      }
      if (isObject(params[key])) {
        formatRequestDate(params[key]);
      }
    }
  }
}
