import { number } from 'vue-types';
import {
  AddOrderItemParams,
  AddOrderItemResultModel,
  DelOrderItemParams,
  DelOrderItemResultModel,
  GetOrderResultModel,
  RepeatOrderResultModel,
} from '../order/model';
import {
  B2bOrderPageParams,
  B2bOrderPageResultModel,
  B2bOrderCancelParams,
  B2bOrderCancelResultModel,
  B2bManualTransferOrderParams,
  CheckOrderB2BResultModel,
  OrderConfirmB2BResultModel,
  B2bOrderRefreshParams,
  B2bOrderIgnoreParams,
  B2bOrderIgnoreResultModel,
  B2bOrderBatchTransferParams,
  B2bOrderBatchTransferResultModel,
  AddB2BSystemRoutingParams,
  AddCtB2BProductListParams,
  AddCtB2BServiceTypeListParams,
  GetB2BCustomerListResultModel,
  GetB2BOrderProductInfoParams,
  GetB2BOrderProductInfoResultModel,
  GetB2BOrderServiceTypeInfoParams,
  GetB2BOrderServiceTypeInfoResultModel,
  GetB2BShopListParams,
  GetB2BShopListResultModel,
  B2bSaveShopParams,
  B2bSaveDataSourceParams,
  GetDataSourceConfigListResultModel,
  FetchServiceTypeAndWarrantyResponseType,
  FetchCtServiceTypePageRequestType,
  FetchCtServiceTypePageResponseItemsType,
  ServiceTypeParams,
  ServiceTypeResultModel,
  ServiceTypeUpdateParams,
  ServiceTypeUpdateResultModel,
  GetCtB2BWarrantyTypePageParams,
  GetCtB2BWarrantyTypePageResultModel,
  FetchB2BDataTreeResultModel,
  GetCtB2BWarrantyTypeDetailParams,
  GetCtB2BWarrantyTypeDetailResultModel,
  UpdateCtB2BWarrantyTypeParams,
  UpdateCtB2BWarrantyTypeResultModel,
  AddCtB2BWarrantyTypeParams,
  AddCtB2BWarrantyTypeResultModel,
  WarrantyTypeResultModel,
  UpdateDelFlagParams,
  UpdateDelFlagResultModel,
  ProductPageParams,
  ProductPageResultModel,
  FetchAllProductsResponseItemsType,
  GetProductPagesParams,
  GetProductPagesResultModel,
  ProductUpdateParams,
  ProductUpdateResultModel,
  ProductSaveParams,
  ProductSaveResultModel,
  ProductDeleteResultModel,
  ProductDeleteParams,
  ImportProductExcelParams,
  ImportProductExcelResultModel,
  ProductCheckProductParams,
  ProductCheckProductResultModel,
  CustomerProductSaveResultModel,
  CustomerProductSaveParams,
} from './model';
import { mdHttp, sdHttp } from '/@/utils/http/axios';

export enum Api {
  B2bOrderPage = '/b2b/order/getPage',
  B2bOrderCancel = '/b2b/order/cancel',
  B2bManualTransferOrder = '/b2b/order/manualTransferOrder',
  AddOrderItemB2B = '/b2b/order/addOrderItem',
  DelOrderItemB2B = '/b2b/order/delOrderItem',
  UpdateOrderItemB2B = '/b2b/order/updateOrderItem',
  CheckOrderB2B = '/b2b/order/checkOrder',
  SaveOrderB2B = '/b2b/order/saveOrder',
  RepeatOrderB2B = '/b2b/order/repeatOrder',
  OrderConfirmB2B = '/b2b/order/confirm',
  B2bOrderRefresh = '/b2b/order/refresh',
  B2bOrderIgnore = '/b2b/order/ignore',
  B2bOrderBatchTransfer = '/b2b/order/batchTransfer',
  GetB2BOrderServiceTypeInfo = '/b2b/config/getB2BOrderServiceTypeInfo',
  AddCtB2BServiceTypeList = '/b2b/config/addCtB2BServiceTypeList',
  GetB2BOrderProductInfo = '/b2b/config/getB2BOrderProductInfo',
  AddCtB2BProductList = '/b2b/config/addCtB2BProductList',
  GetB2BCustomerList = '/b2b/config/getCustomerList',
  GetB2BShopList = '/b2b/config/getShopList',
  AddB2BSystemRouting = '/b2b/config/addB2BSystemRouting',
  B2bSaveShop = '/b2b/save/shop',
  B2bSaveDataSource = '/ct/b2b/customer/shop/dataSource/save',
  GetDataSourceConfigList = '/b2b/config/getDataSourceConfigList',
  getServiceTypeAndWarranty = '/common/serviceTypeTag/getServiceTypeAndWarranty',
  getServiceTypePage = '/ct/b2b/customer/serviceType/page',
  RemoveCtServiceType = '/ct/b2b/customer/serviceType/',
  ServiceType = '/ct/b2b/customer/serviceType',
  ServiceTypeUpdate = '/ct/b2b/customer/serviceType/update',
  GetCtB2BWarrantyTypePage = '/ct/warrantyType/getCtB2BWarrantyTypePage',
  FetchB2BDataTree = '/common/b2b/ct/shop/tree',
  GetCtB2BWarrantyTypeDetail = '/ct/warrantyType/getCtB2BWarrantyTypeDetail/',
  UpdateCtB2BWarrantyType = '/ct/warrantyType/updateCtB2BWarrantyType',
  AddCtB2BWarrantyType = '/ct/warrantyType/addCtB2BWarrantyType',
  WarrantyType = '/ct/warrantyType/',
  UpdateDelFlag = '/ct/warrantyType/updateDelFlag',
  ProductPage = '/ct/b2b/customer/product/page',
  FetchCustomerProductList = "/ct/manage/getProductList",
  GetProductPages = '/ct/b2b/customer/product',
  ProductUpdate = '/ct/b2b/customer/product/update',
  ProductSave = '/ct/b2b/customer/product/save',
  ProductDelete = '/ct/b2b/customer/product/delete',
  ImportProductExcel = '/ct/b2b/customer/product/importProductExcel',
  ProductCheckProduct = '/ct/b2b/customer/product/checkProduct',
  CustomerProductSave = '/ct/b2b/customer/product/save',
  getAllList = '/ct/shop/getAllList/'

}

// 1.分页查询,
export function b2bOrderPage(params: B2bOrderPageParams) {
  return sdHttp.get<B2bOrderPageResultModel>({
    url: Api.B2bOrderPage,
    params,
  });
}

// 2.取消工单,
export function b2bOrderCancel(data: B2bOrderCancelParams) {
  return sdHttp.post<B2bOrderCancelResultModel[]>({
    url: Api.B2bOrderCancel,
    data,
  });
}

// 8.手工转单,
export function b2bManualTransferOrder(params: B2bManualTransferOrderParams) {
  return sdHttp.get<GetOrderResultModel>({
    url: Api.B2bManualTransferOrder,
    params,
  });
}

// 9.添加下单产品,
export function addOrderItemB2B(data: AddOrderItemParams) {
  return sdHttp.post<AddOrderItemResultModel>({
    url: Api.AddOrderItemB2B,
    data,
  });
}

// 10.删除产品,
export function delOrderItemB2B(data: DelOrderItemParams) {
  return sdHttp.delete<DelOrderItemResultModel>({
    url: Api.DelOrderItemB2B,
    params: data,
  });
}

// 11.修改产品,
export function updateOrderItemB2B(data: AddOrderItemParams) {
  return sdHttp.put<AddOrderItemResultModel>({
    url: Api.UpdateOrderItemB2B,
    data,
  });
}

// 12.校验工单是否重单,
export function checkOrderB2B(data: GetOrderResultModel) {
  return sdHttp.post<CheckOrderB2BResultModel>({
    url: Api.CheckOrderB2B,
    data,
  });
}

// 13.保存工单,
export function saveOrderB2B(data: GetOrderResultModel) {
  return sdHttp.post<boolean>({
    url: Api.SaveOrderB2B,
    data,
  });
}

// 14.重单,
export function repeatOrderB2B(tempOrderKey: string) {
  return sdHttp.post<RepeatOrderResultModel>({
    url: Api.RepeatOrderB2B,
    data: { tempOrderKey },
  });
}

// 15.确认费用信息,
export function orderConfirmB2B(tempOrderKey: string) {
  return sdHttp.post<OrderConfirmB2BResultModel>({
    url: Api.OrderConfirmB2B,
    data: { tempOrderKey },
  });
}

// 7.刷新,
export function b2bOrderRefresh(params: B2bOrderRefreshParams) {
  return sdHttp.get<boolean>({
    url: Api.B2bOrderRefresh,
    params,
  });
}

// 3.忽略隐藏,
export function b2bOrderIgnore(data: B2bOrderIgnoreParams) {
  return sdHttp.post<B2bOrderIgnoreResultModel[]>({
    url: Api.B2bOrderIgnore,
    data,
  });
}

// 4.批量转单,
export function b2bOrderBatchTransfer(data: B2bOrderBatchTransferParams) {
  return sdHttp.post<B2bOrderBatchTransferResultModel[]>({
    url: Api.B2bOrderBatchTransfer,
    data,
  });
}

// 1.查询一键配置-服务类型关联配置界面信息,
export function getB2BOrderServiceTypeInfo(params: GetB2BOrderServiceTypeInfoParams) {
  return sdHttp.get<GetB2BOrderServiceTypeInfoResultModel>({
    url: Api.GetB2BOrderServiceTypeInfo,
    params,
  });
}

// 3.保存B2B服务类型关联配置,
export function addCtB2BServiceTypeList(data: AddCtB2BServiceTypeListParams) {
  return mdHttp.post<boolean>({
    url: Api.AddCtB2BServiceTypeList,
    data,
  });
}

// 2.查询一键配置-客户产品关联配置界面信息,
export function getB2BOrderProductInfo(params: GetB2BOrderProductInfoParams) {
  return sdHttp.get<GetB2BOrderProductInfoResultModel>({
    url: Api.GetB2BOrderProductInfo,
    params,
  });
}

// 4.保存B2B客户产品关联配置,
export function addCtB2BProductList(data: AddCtB2BProductListParams) {
  return mdHttp.post<boolean>({
    url: Api.AddCtB2BProductList,
    data,
  });
}

// 1.查询客户列表(当前用户可见),
export function getB2BCustomerList() {
  return mdHttp.get<GetB2BCustomerListResultModel>({
    url: Api.GetB2BCustomerList,
  });
}

// 2.查询客户下的店铺列表(当前用户可见),
export function getB2BShopList(params: GetB2BShopListParams) {
  return mdHttp.get<GetB2BShopListResultModel>({
    url: Api.GetB2BShopList,
    params,
  });
}

// 5.保存B2B系统路由配置, 系统转发
export function addB2BSystemRouting(data: AddB2BSystemRoutingParams) {
  return mdHttp.post<boolean>({
    url: Api.AddB2BSystemRouting,
    data,
  });
}

// 3.新增店铺,
export function b2bSaveShop(data: B2bSaveShopParams) {
  return mdHttp.post<boolean>({
    url: Api.B2bSaveShop,
    data,
  });
}

// 4.新增店铺配置,
export function b2bSaveDataSource(data: B2bSaveDataSourceParams) {
  return mdHttp.post<boolean>({
    url: Api.B2bSaveDataSource,
    data,
  });
}

// 4.查询数据源配置,
export function getDataSourceConfigList() {
  return sdHttp.get<GetDataSourceConfigListResultModel>({
    url: Api.GetDataSourceConfigList,
  });
}

// B2B快可立服务类型 后端不给yApi
export function getServiceTypeAndWarranty() {
  return mdHttp.get<FetchServiceTypeAndWarrantyResponseType[]>({
    url: Api.getServiceTypeAndWarranty,
  });
}

export function getServiceTypePage(params: FetchCtServiceTypePageRequestType) {
  return mdHttp.get<FetchCtServiceTypePageResponseItemsType>({
    url: Api.getServiceTypePage,
    params
  });

}


export function RemoveCtServiceType(params: { id: string | number }) {
  return mdHttp.delete<FetchCtServiceTypePageResponseItemsType>({
    url: Api.RemoveCtServiceType + params.id
  });

}


// 3.详情,
export function serviceType(params: ServiceTypeParams) {
  return mdHttp.get<ServiceTypeResultModel>({
    url: Api.ServiceType,
    params,
  });
}


// 4.编辑数据,
export function serviceTypeUpdate(data: ServiceTypeUpdateParams) {
  return mdHttp.put<ServiceTypeUpdateResultModel>({
    url: Api.ServiceTypeUpdate,
    data,
  });
}

// 2.分页查询质保类型关联配置,

export function getCtB2BWarrantyTypePage(params: GetCtB2BWarrantyTypePageParams) {
  return mdHttp.get<GetCtB2BWarrantyTypePageResultModel>({
    url: Api.GetCtB2BWarrantyTypePage,
    params,
  });
}



export function FetchB2BDataTree() {
  return mdHttp.get<FetchB2BDataTreeResultModel[]>({
    url: Api.FetchB2BDataTree,
  });
}


export function getCtB2BWarrantyTypeDetail(params: { id: string | number }) {
  return mdHttp.get<GetCtB2BWarrantyTypeDetailResultModel>({
    url: Api.GetCtB2BWarrantyTypeDetail + params.id,

  });
}


// 4.修改质保类型关联配置,
export function updateCtB2BWarrantyType(data: UpdateCtB2BWarrantyTypeParams) {
  return mdHttp.put<UpdateCtB2BWarrantyTypeResultModel>({
    url: Api.UpdateCtB2BWarrantyType,
    data,
  });
}

// 3.新增质保类型关联配置,
export function addCtB2BWarrantyType(data: AddCtB2BWarrantyTypeParams) {
  return mdHttp.post<AddCtB2BWarrantyTypeResultModel>({
    url: Api.AddCtB2BWarrantyType,
    data,
  });
}


// 启用/禁用,
export function warrantyType(data: { id: string | number }) {
  return mdHttp.delete<WarrantyTypeResultModel>({
    url: Api.WarrantyType + data.id
  });
}


// 批量启用/禁用客户质保类型数据,
export function updateDelFlag(data: UpdateDelFlagParams) {
  return mdHttp.put<UpdateDelFlagResultModel>({
    url: Api.UpdateDelFlag,
    data,
  });
}

// 1.分页获取客户产品列表,
export function productPage(params: ProductPageParams) {
  return mdHttp.get<ProductPageResultModel>({
    url: Api.ProductPage,
    params,
  });
}


export function FetchCustomerProductList(params: { customerId: number | string }) {
  return mdHttp.get<FetchAllProductsResponseItemsType>({
    url: Api.FetchCustomerProductList,
    params,
  });
}

// 3.详情,
export function getProductPages(params: GetProductPagesParams) {
  return mdHttp.get<GetProductPagesResultModel>({
    url: Api.GetProductPages,
    params,
  });
}


// 4.修改,
export function productUpdate(data: ProductUpdateParams) {
  return mdHttp.put<ProductUpdateResultModel>({
    url: Api.ProductUpdate,
    data,
  });
}

// 2.新建客户产品,
export function productSave(data: ProductSaveParams) {
  return mdHttp.post<ProductSaveResultModel>({
    url: Api.ProductSave,
    data,
  });
}


// 5.删除,
export function productDelete(data: ProductDeleteParams) {
  return mdHttp.delete<ProductDeleteResultModel>({
    url: Api.ProductDelete + "?" + 'id=' + data.id,
  });
}


// 6.导入客户产品Excel表格,
export function importProductExcel(customerId: number, data: FormData) {
  return mdHttp.post<ImportProductExcelResultModel[]>({
    url: Api.ImportProductExcel,
    data,
    params: { customerId },
  });
}


// 检查导入表格数据是否正确,
export function productCheckProduct(data: ProductCheckProductParams, id: number) {
  return mdHttp.post<ProductCheckProductResultModel>({
    url: Api.ProductCheckProduct + '/' + id,
    data,
  });
}

// 2.新建客户产品,
export function customerProductSave(data: CustomerProductSaveParams) {
  return mdHttp.post<CustomerProductSaveResultModel>({
    url: Api.CustomerProductSave,
    data,
  });
}
//后端不给文档
export function getAllList(data: { id: string }) {
  return mdHttp.get<{
    "id": number,
    "name": string
  }[]>({
    url: Api.getAllList + data.id,
  });
}