import { AxiosRequestConfig } from 'axios';
import sign from '../../sign';

const cacheMap = new Map();

const setKey = (config: AxiosRequestConfig) => {
  return sign(config);
};

export const setAxiosCache = (config: AxiosRequestConfig, data: any) => {
  cacheMap.set(setKey(config), data);
};

export const getAxiosCache = (config: AxiosRequestConfig) => {
  const data = cacheMap.get(setKey(config));
  return data;
};
