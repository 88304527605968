import { RouterMap } from '../../types';

export const routeMap: RouterMap = new Map();

routeMap.set('/network/settled/audit', {
  name: 'NetworkSettledAudit',
  meta: {
    nav: [
      { id: '0', title: '入驻申请' },
      { id: '1', title: '入驻驳回' },
      { id: '2', title: '入驻撤销' },
      { id: '3', title: '意向入驻' },
      { id: '4', title: '已审核' },
      // { id: '3', title: '入驻成功' },
    ],
  },
});
