import { RouterMap } from '../../types';

export const routeMap: RouterMap = new Map();

routeMap.set('/outside/exportTask', {
  name: 'ExportTask',
});

routeMap.set('/outside/statistics/completionDetails', {
  name: 'completionDetails',

  // 财务完工明细
});

routeMap.set('/outside/statistics/dailyFollowUpList', {
  name: 'dailyFollowUpList',
  meta: {
    nav: [
      { id: '10', title: '客服回访统计' },
      { id: '20', title: '客服回访统计' },
      { id: '30', title: '客服回访明细' },
    ],
  },

  // 工单每日回访审单统计
});

routeMap.set('/outside/statistics/followUp', {
  name: 'followUp',
  // 客服工单每日回访审单回访列表
});

routeMap.set('/outside/statistics/followUpDetail', {
  name: 'followUpDetail',
  // 客服工单每日回访审单回访明细
});

routeMap.set('/outside/statistics/servicePointFee', {
  name: 'servicePointFee',
  meta: {
    nav: [
      { id: '10', title: '网点对账单' },
      { id: '20', title: '网点完工单' },
      { id: '30', title: '冻结账户' },
      { id: '40', title: '网点积分明细' },
    ],
  },
  // 网点对账单
});

routeMap.set('/outside/statistics/customerStatement', {
  name: 'customerStatement',
  meta: {
    nav: [
      { id: '10', title: '客户对账单' },
      { id: '20', title: '客户完工单' },
      { id: '30', title: '客户退单/取消单' },
      { id: '40', title: '客户退补单' },
    ],
  },
  // 客户对账单
});

routeMap.set('/outside/statistics/dailyPlaceOrder', {
  name: 'dailyPlaceOrder',
  meta: {
    nav: [
      { id: '10', title: '客户每日下单' },
      { id: '20', title: '客户工单趋势' },
    ],
  },
  // 客户每日下单
});

routeMap.set('/outside/statistics/monthPlaceOrder', {
  name: 'monthPlaceOrder',
  meta: {
    nav: [
      { id: '10', title: '客户每月下单' },
      { id: '20', title: '客户工单趋势' },
    ],
  },
  // 客户每月下单
});

routeMap.set('/outside/statistics/finished', {
  name: 'finished',

  // 客户完工单
});

routeMap.set('/outside/statistics/retrieve', {
  name: 'retrieve',
  // 客户退补单
});

routeMap.set('/outside/chart/bulletinBoard', {
  name: 'bulletinBoard',
  // 图表
});

routeMap.set('/outside/chart/monthlyData', {
  name: 'monthlyData',
  // 图表
});

routeMap.set('/outside/chart/checkData', {
  name: 'checkData',
  // 图表
});

routeMap.set('/outside/chart/scheduleData', {
  name: 'scheduleData',
  // 图表
});

routeMap.set('/outside/chart/oldSystem', {
  name: 'oldSystem',
  // 图表
});

routeMap.set('/outside/chart/coreData', {
  name: 'coreData',
  // 图表
});

routeMap.set('/outside/statistics/RechargeDetails', {
  name: 'RechargeDetails',
  meta: {
    nav: [
      { id: '10', title: '客户充值汇总' },
      { id: '20', title: '客户充值明细' },
      { id: '30', title: '其他充值' },
    ],
  },
  // 充值明细
});

routeMap.set('/outside/statistics/uncompletedDetails', {
  name: 'uncompletedDetails',
  // 未完工明细
});

routeMap.set('/outside/statistics/uncompletedSummary', {
  name: 'uncompletedSummary',

  // 未完工汇总
});

routeMap.set('/outside/statistics/customerServicePositiveFeedbackDetails', {
  name: 'customerServicePositiveFeedbackDetails',
  // 客服好评明细
});

routeMap.set('/outside/statistics/qualityDeposit', {
  name: 'qualityDeposit',
  meta: {
    nav: [
      { id: '10', title: '质保金汇总' },
      { id: '20', title: '质保金明细' },
    ],
  },
  // 质保金总汇
});

routeMap.set('/outside/statistics/monthlyPerformanceRank', {
  name: 'monthlyPerformanceRank',
  meta: {
    nav: [
      { id: '10', title: '业务业绩月排名' },
      { id: '20', title: '业务业绩月明细' },
      { id: '30', title: '业务业绩月分析' },
    ],
  },
  // 业务业绩月排名
});

routeMap.set('/outside/statistics/annualPerformanceRank', {
  name: 'annualPerformanceRank',
  meta: {
    nav: [
      { id: '10', title: '业务业绩年排名' },
      { id: '20', title: '业务业绩报表' },
    ],
  },
  // 业务业绩年排名
});

routeMap.set('/outside/statistics/csDailyRefunds', {
  name: 'csDailyRefunds',
  // 客服每日退单
});

routeMap.set('/outside/statistics/branchPayment', {
  name: 'branchPayment',
  meta: {
    nav: [
      { id: '10', title: '网点付款总汇' },
      { id: '20', title: '网点付款清单' },
    ],
  },

  // 网点付款部署
});

routeMap.set('/outside/statistics/branchWorkOrders', {
  name: 'branchWorkOrders',
  // 网点工单费用
});

routeMap.set('/outside/statistics/complaintRate', {
  name: 'complaintRate',
  meta: {
    nav: [
      { id: '10', title: '省投诉率' },
      { id: '20', title: '市投诉率' },
      { id: '30', title: '区/县投诉率' },
    ],
  },
  // 省投诉率
});

routeMap.set('/outside/statistics/complaintSummary', {
  name: 'complaintSummary',
  // 省市区投诉汇总
  meta: {
    nav: [
      { id: '10', title: '省投诉率' },
      { id: '20', title: '市投诉率' },
      { id: '30', title: '区/县投诉率' },
    ],
  },
});

routeMap.set('/outside/statistics/customerReceivable', {
  name: 'customerReceivable',
  // 客户应收汇总
});

routeMap.set('/outside/statistics/csWorkCompleted', {
  name: 'csWorkCompleted',
  meta: {
    nav: [
      { id: '10', title: '客服每日完工' },
      { id: '20', title: '客服每月完工' },
    ],
  },
  // 客服完工
});

routeMap.set('/outside/statistics/reminderReply', {
  name: 'reminderReply',
  // 催单回复
});


routeMap.set('/outside/statistics/accountBalance', {
  name: 'accountBalance',
  // 网点账户余额
});

routeMap.set('/outside/statistics/cashBackDetails', {
  name: 'cashBackDetails',
  // 返现明细
})

routeMap.set('/outside/statistics/dailyCompletionTime', {
  name: 'dailyCompletionTime',
  // 每日完工时效
})

routeMap.set('/outside/statistics/remoteCostRank', {
  name: 'remoteCostRank',
  // 远程费用排名
})

routeMap.set('/outside/statistics/userFeedback', {
  name: 'userFeedback',
  // 客户投诉
})

routeMap.set('/outside/statistics/customerAccountBalance', {
  name: 'customerAccountBalance',
  // 客户账户余额
})

routeMap.set('/outside/statistics/customerDailyReminders', {
  name: 'customerDailyReminders',
  // 客户每日催单
})


routeMap.set('/outside/statistics/customerServiceCompletionTime', {
  name: 'customerServiceCompletionTime',
  // 客服完成时效
})

routeMap.set('/outside/statistics/dotCostRank', {
  name: 'dotCostRank',
  // 网点成本排名
})

routeMap.set('/outside/statistics/dispatchOrderStatistics', {
  name: 'dispatchOrderStatistics',
  // 接派单统计
})

routeMap.set('/outside/statistics/hasCompleted', {
  name: 'hasCompleted',
  // 省市区完工
  meta: {
    nav: [
      { id: '10', title: '省日完工' },
      { id: '20', title: '市日完工' },
      { id: '30', title: '区/县日完工' },
    ],
  },
})

routeMap.set('/outside/statistics/productAverageCost', {
  name: 'productAverageCost',
  // 省市区完工
  // meta: {
  //   nav: [
  //     { id: '10', title: '空调均单费用' },
  //     { id: '20', title: '厨卫均单费用' },
  //   ],
  // },
})

routeMap.set('/outside/statistics/cover', {
  name: 'cover',
  // 省市区完工
  meta: {
    nav: [
      { id: '10', title: '网点覆盖区域' },
      { id: '20', title: '网点未覆盖区域' },
    ],
  },
})


routeMap.set('/outside/statistics/orderDetails', {
  name: 'orderDetails',
  // 客户下单明细
})

routeMap.set('/outside/statistics/paymentSummary', {
  name: 'paymentSummary'
})


routeMap.set('/outside/statistics/workOrderTimeliness', {
  name: 'workOrderTimeliness'
})


routeMap.set('/outside/statistics/branchFlow', {
  name: 'branchFlow'
  // 网点流水表
})


routeMap.set('/outside/statistics/customerFlow', {
  name: 'customerFlow'
  // 客户流水表
})

routeMap.set('/outside/statistics/cityPlaceAnOrder', {
  name: 'cityPlaceAnOrder'
  // 省市区每日下单
})


routeMap.set('/outside/statistics/specialExpenses', {
  name: 'specialExpenses',
  meta: {
    nav: [
      { id: '10', title: '省市特殊费用分布表' },
      { id: '20', title: '区/县特殊费用分布表' },
    ],
  },
  // 特殊费用
})

routeMap.set('/outside/statistics/dailyComplaints', {
  name: 'dailyComplaints'
  // 每日投诉统计
})

routeMap.set('/outside/statistics/customerRefund', {
  name: 'customerRefund',
  // 客户退单
})

routeMap.set('/outside/statistics/customerRevenue', {
  name: 'customerRevenue',
  meta: {
    nav: [
      { id: '10', title: '客户营收明细' },
      { id: '20', title: '客户营收排名' },
      { id: '30', title: '业务营收明细' },
      { id: '40', title: '营收趋势' },
      { id: '50', title: '产品营收明细' },
    ],
  },
  // 客户营收
})

routeMap.set('/outside/statistics/completeWorkOrderDetails', {
  name: 'completeWorkOrderDetails',
  // 工单完工明细
})

routeMap.set('/outside/statistics/customerOrderDetails', {
  name: 'customerOrderDetails',
})

routeMap.set('/outside/chart/monthlyData', {
  name: 'monthlyData',
});

routeMap.set('/outside/chart/bulletinBoard', {
  name: 'bulletinBoard',
});

routeMap.set('/outside/chart/checkData', {
  name: 'checkData',
});

routeMap.set('/outside/chart/scheduleData', {
  name: 'scheduleData',
});

routeMap.set('/outside/chart/oldSystem', {
  name: 'oldSystem',
});

routeMap.set('/outside/chart/coreData', {
  name: 'coreData',
});

routeMap.set('/outside/statistics/compensateFor', {
  name: 'compensateFor'
})

routeMap.set('/outside/statistics/positiveReviews', {
  name: 'positiveReviews'
})

routeMap.set('/outside/statistics/reconciliation', {
  name: 'reconciliation'
})