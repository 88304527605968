import {
  AddCtChatParams,
  ArtificialAssignParams,
  ArtificialAssignResultModel,
  BatchUpdateParams,
  CheckRepeatOrderParams,
  ConfirmAssignParams,
  GetBaseSelectMsgParams,
  GetBaseSelectMsgResultModel,
  GetCompleteDetailParams,
  GetCompleteDetailResultModel,
  GetNodeOverviewParams,
  GetOrderCtChatParams,
  GetOrderCtChatResultModel,
  GetOrderDetailParams,
  GetOrderDetailResultModel,
  GetOrderNetworkListParams,
  GetOrderNetworkListResultModel,
  GetOrderSpDetailResultModel,
  GetTimelinessParams,
  GetTimelinessResultModel,
  GetTrackingRemarkParams,
  GetTrackingRemarkResultModel,
  ReminderParams,
  SaveOrderPartsParams,
  SaveTrackingRemarkParams,
  UpdateOrderConsumerParams,
  getOrderLogResultModel,
  viewSpecificationParams,
  viewSpecificationResultModel,
  CashbackFromParams,
  CashbackFromResultModel,
  SaveApplyCashbackParams,
  GetOrderGodChatParams,
  GetListByOrderIdParams,
  GetListByOrderIdResultModel,
  GetVoiceTaskResultModel,
  GetUpdateLogParams,
  GetUpdateLogResultModel,
  GetSpEngBindParams,
  GetSpEngPhoneParams,
  PutOrderRemarkParams,
  UrgentParams,
  GetTrackingAllLengthResultModel,
  GetSpecialFeeItemParams,
  GetSpecialFeeItemResultModel,
  SaveSpRemarksParams,
  GetSpRemarksResultModel,
  GetOldHisPlanListParams,
  GetOldHisPlanListResultModel,
  GetHisPlanListParams,
  GetHisPlanListResultModel,
  IsPartsCollectBarcodeParams,
  IsPartsCollectBarcodeResultModel,
  GetBaseSelectMsgNewParams,
  GetBaseSelectMsgNewResultModel,
  PraiseGetAllParams,
  PraiseGetAllResultModel,
} from './model';
import { PageType } from '/@/myComponents/OrderDetail/type';
import { aivoiceHttp, mdHttp, sdHttp } from '/@/utils/http/axios';

export enum Api {
  GetNodeDetail = '/order/detail/get',
  GetNodeOverview = '/order/detail/overview',
  CheckRepeatOrder = '/order/kefu/artificialPlan/checkRepeatOrder',
  UpdateOrderConsumer = '/order/kefu/artificialPlan/updateOrderConsumer',
  ViewSpecification = '/common/operating/specification',
  GetCompleteDetail = '/order/complete/getCompleteDetail',
  NetworkList = '/order/kefu/artificialPlan/selectForPlan',
  Assign = '/order/kefu/artificialPlan/plan',
  ConfirmAssign = '/order/kefu/artificialPlan/confirmPlan',
  GetOrderSpDetail = '/order/kefu/artificialPlan/getOrderSpDetail',
  BatchUpdate = '/sp/tag/batchUpdate',
  GetTimeliness = '/common/timelinessRule/getTimeliness',
  GetBaseSelectMsg = '/order/parts/getBaseSelectMsg',
  SaveOrderParts = '/order/parts/saveOrderParts',
  GetOrderCtChat = '/order/kefu/ctChat/getOrderCtChat',
  AddCtChat = '/order/kefu/ctChat',
  GetOrderSpChat = '/order/kefu/spChat/getOrderSpChat',
  AddSpChat = '/order/kefu/spChat',
  GetOrderGodChat = '/order/god/chat/getList',
  GetTrackingRemark = '/order/remark/getRemark',
  SaveTrackingRemark = '/order/remark',
  GetOrderLog = '/orderLog/log',
  RemarkOptions = '/pf/trackProgress/remarkOptions',
  Reminder = '/order/registration/information/reminder',
  ComplainRemarkOptions = '/pf/trackProgress/getTrackProgressByCodeAndValueFromRedis/',
  CashbackFrom = '/order/cashback/cashbackFrom/',
  SaveApplyCashback = '/order/cashback/cashbackFrom/saveApplyCashback',
  GetListByOrderId = '/dialLog/getListByOrderId/',
  GetVoiceTask = '/xa/getVoiceTask',
  GetUpdateLog = '/common/updateLog/getUpdateLog',
  GetSpEngBind = '/spEngBind/bind',
  GetSpEngPhone = '/spEngGetLog/getMobile',
  PutOrderRemark = '/order/remark',
  GetSpecialFeeItem = '/common/feeItem/getSpecialFeeItem',
  Urgent = '/order/urgent',
  GetTrackingAllLength = '/order/common/length/getAllLength',
  GetOrderReminderCount = '/order/reminder/getOrderReminderCount',
  SaveSpRemarks = '/sp/saveSpRemarks',
  GetSpRemarks = '/sp/getSpRemarks/',
  GetOldHisPlanList = '/order/page/getOldHisPlanList',
  GetHisPlanList = '/order/page/getHisPlanList',
  IsPartsCollectBarcode = '/order/parts/isCollectBarcode',
  GetBaseSelectMsgNew = '/order/parts/getBaseSelectMsgNew',
  PraiseGetAll = '/order/praise/getAll/',
}

// 工单详情,
export function getNodeDetail(params: GetOrderDetailParams) {
  return sdHttp.get<GetOrderDetailResultModel>({
    url: Api.GetNodeDetail,
    params,
  });
}

// 工单概览
export function getNodeOverview(params: GetNodeOverviewParams) {
  return sdHttp.get<GetOrderDetailResultModel>({
    url: Api.GetNodeOverview,
    params,
  });
}

// ----------- 人工派单  -----------
// 修改消费者重单检查,
export function checkRepeatOrder(params: CheckRepeatOrderParams) {
  return sdHttp.get<boolean>({
    url: Api.CheckRepeatOrder,
    params,
  });
}

// 修改消费者信息
export function updateOrderConsumer(data: UpdateOrderConsumerParams) {
  return sdHttp.put<number>({
    url: Api.UpdateOrderConsumer,
    data,
  });
}

// 网点列表
export function getOrderNetworkList(params: GetOrderNetworkListParams) {
  return sdHttp.get<GetOrderNetworkListResultModel>({
    url: Api.NetworkList,
    params,
  });
}

// 派单
export function artificialAssign(params: ArtificialAssignParams) {
  return sdHttp.get<ArtificialAssignResultModel>({
    url: Api.Assign,
    params,
  });
}

// 确认派单
export function confirmAssign(data: ConfirmAssignParams) {
  return sdHttp.post<boolean>({
    url: Api.ConfirmAssign,
    data,
  });
}

// 网点详情数据
export function getOrderSpDetail(params: {
  orderId: string;
  servicePointId: number;
  createTime: number;
}) {
  return sdHttp.get<GetOrderSpDetailResultModel>({
    url: `${Api.GetOrderSpDetail}/${params.orderId}/${params.createTime}/${params.servicePointId}`,
  });
}

// 修改网点表情标签信息数据
export function batchUpdate(data: BatchUpdateParams[]) {
  return mdHttp.put<number>({
    url: Api.BatchUpdate,
    data,
  });
}

// ----------- 下单信息 -----------
// 查看规范
export function viewSpecification(params: viewSpecificationParams) {
  return mdHttp.get<viewSpecificationResultModel>({
    url: Api.ViewSpecification,
    params,
  });
}

// 获取完工明细,
export function getCompleteDetail(params: GetCompleteDetailParams) {
  return sdHttp.get<GetCompleteDetailResultModel>({
    url: Api.GetCompleteDetail,
    params,
  });
}

// 获取工单收件人信息及产品关联的配件信息,
export function getBaseSelectMsg(params: GetBaseSelectMsgParams) {
  return sdHttp.get<GetBaseSelectMsgResultModel>({
    url: Api.GetBaseSelectMsg,
    params,
  });
}

// 保存配件单
export function saveOrderParts(data: SaveOrderPartsParams) {
  return sdHttp.post<number>({
    url: Api.SaveOrderParts,
    data,
  });
}

// ----------- 服务明细 -----------
// 规则详情,
export function getTimeliness(params: GetTimelinessParams) {
  return mdHttp.get<GetTimelinessResultModel>({
    url: Api.GetTimeliness,
    params,
  });
}

// ----------- 跟踪进度 -----------
// 工单详情-查询客户反馈内容
export function getOrderCtChat(params: GetOrderCtChatParams) {
  return sdHttp.get<GetOrderCtChatResultModel[]>({
    url: Api.GetOrderCtChat,
    params,
  });
}

// 工单详情- 新增客户反馈内容
export function addCtChat(data: AddCtChatParams) {
  return sdHttp.post<number>({
    url: Api.AddCtChat,
    data,
  });
}

// 工单详情-查询网点反馈内容
export function getOrderSpChat(params: GetOrderCtChatParams) {
  return sdHttp.get<GetOrderCtChatResultModel[]>({
    url: Api.GetOrderSpChat,
    params,
  });
}

// 工单详情- 新增网点反馈内容
export function addSpChat(data: AddCtChatParams) {
  return sdHttp.post<number>({
    url: Api.AddSpChat,
    data,
  });
}

// 工单详情-查询消费者反馈内容
export function getOrderGodChat(params: GetOrderGodChatParams) {
  return sdHttp.get<GetOrderCtChatResultModel[]>({
    url: Api.GetOrderGodChat,
    params,
  });
}

// 工单详情-获取日志
export function getOrderLog(orderId: string, createTime: number) {
  return sdHttp.get<getOrderLogResultModel>({
    url: Api.GetOrderLog,
    params: { orderId, createTime },
  });
}

// 工单详情-跟踪进度-跟进备注列表
export function getTrackingRemark(params: GetTrackingRemarkParams) {
  return sdHttp.get<GetTrackingRemarkResultModel[]>({
    url: Api.GetTrackingRemark,
    params,
  });
}

// 工单详情-跟踪进度-保存跟进备注
export function saveTrackingRemark(data: SaveTrackingRemarkParams) {
  return sdHttp.post<number>({
    url: Api.SaveTrackingRemark,
    data,
  });
}

// 工单详情-跟踪进度-获取工单备注选项
export function remarkOptions(params: {
  orderTypeId?: number; // 工单类型ID
  orderNodeId?: number; // 工单节点ID
}) {
  return mdHttp.get<string[]>(
    {
      url: Api.RemarkOptions,
      params,
    },
    { cache: true },
  );
}

// 催保内外审核,
export function reminder(data: ReminderParams) {
  return sdHttp.post<boolean>({
    url: Api.Reminder,
    data,
  });
}

// 投诉单详情-跟踪进度-获取工单备注选项
export function complainRemarkOptions(params: {
  code: string; // 工单类型编码
  nodeValue: number; // 节点value值
}) {
  return mdHttp.get<string[]>({
    url: Api.ComplainRemarkOptions + params.code + '/' + params.nodeValue,
  });
}

// 获取申请返现单详情页面数据,
export function cashbackFrom(params: CashbackFromParams) {
  return sdHttp.get<CashbackFromResultModel>({
    url: Api.CashbackFrom + params.strOrderId + '/' + params.orderCreateTime,
    params,
  });
}

// 保存申请返现单,
export function saveApplyCashback(data: SaveApplyCashbackParams) {
  return sdHttp.post<boolean>({
    url: Api.SaveApplyCashback,
    data,
  });
}

// 工单详情查看通话记录,
export function getListByOrderId(params: GetListByOrderIdParams) {
  return sdHttp.get<GetListByOrderIdResultModel[]>({
    url: Api.GetListByOrderId + params.orderId + '/' + params.createTime,
    params,
  });
}

// 读取语音回访任务,
export function getVoiceTask(params: GetListByOrderIdParams) {
  return aivoiceHttp.get<GetVoiceTaskResultModel>({
    url: Api.GetVoiceTask,
    params,
  });
}

// 1.查询更新动态,
export function getUpdateLog(params: GetUpdateLogParams) {
  return mdHttp.get<GetUpdateLogResultModel>({
    url: Api.GetUpdateLog,
    params,
  });
}

// 联系人信息获取虚拟号
export function getSpEngBind(params: GetSpEngBindParams) {
  return sdHttp.get<string>(
    {
      url: Api.GetSpEngBind,
      params,
    },
    { hideLoading: true },
  );
}

// 联系人信息获取真实号码
export function getSpEngPhone(params: GetSpEngPhoneParams) {
  return sdHttp.get<string>(
    {
      url: Api.GetSpEngPhone,
      params,
    },
    { hideLoading: true },
  );
}

// 修改跟踪进度可见性,
export function putOrderRemark(data: PutOrderRemarkParams) {
  return sdHttp.put<boolean>({
    url: Api.PutOrderRemark,
    data,
  });
}

// 获取费用配置项,
export function getSpecialFeeItem(params: GetSpecialFeeItemParams) {
  return mdHttp.get<GetSpecialFeeItemResultModel[]>({
    url: Api.GetSpecialFeeItem,
    params,
  });
}

// 加急
export function urgent(data: UrgentParams) {
  return sdHttp.post<boolean>({
    url: Api.Urgent,
    data,
  });
}

// 获取跟踪进度所有数量统计
export function getTrackingAllLength(params: { orderId: string; createTime: number }) {
  return sdHttp.get<GetTrackingAllLengthResultModel>({
    url: `${Api.GetTrackingAllLength}/${params.orderId}/${params.createTime}`,
  });
}

// 获取催单统计
export function getOrderReminderCount(type: PageType) {
  return sdHttp.get<number>({
    url: Api.GetOrderReminderCount,
    params: { type },
  });
}

// 保存派单备注
export function saveSpRemarks(data: SaveSpRemarksParams) {
  return mdHttp.post<boolean>({
    url: Api.SaveSpRemarks,
    data,
  });
}

// 获取网点派单历史
export function getSpRemarks(id: number) {
  return mdHttp.get<GetSpRemarksResultModel>({
    url: Api.GetSpRemarks + id,
  });
}

// 4.获取旧系统的历史派单记录,
export function getOldHisPlanList(params: GetOldHisPlanListParams) {
  return sdHttp.get<GetOldHisPlanListResultModel>({
    url: Api.GetOldHisPlanList,
    params,
  });
}

// 3.历史派单,
export function getHisPlanList(params: GetHisPlanListParams) {
  return sdHttp.get<GetHisPlanListResultModel>({
    url: Api.GetHisPlanList,
    params,
  });
}


// 配件申请时是否收集条码,
export function isPartsCollectBarcode(params: IsPartsCollectBarcodeParams) {
  return sdHttp.get<IsPartsCollectBarcodeResultModel>({
    url: Api.IsPartsCollectBarcode,
    params,
  });
}



// 1、校验条码，根据校验成功的条码获取配件信息 2、不校验条码，获取所有产品关联的配件信息,
export function getBaseSelectMsgNew(data: GetBaseSelectMsgNewParams) {
  return sdHttp.post<GetBaseSelectMsgNewResultModel>({
    url: Api.GetBaseSelectMsgNew,
    data,
  });
}






// 22.好评、日志概览列表,
export function praiseGetAll(params: PraiseGetAllParams) {
  return sdHttp.get<PraiseGetAllResultModel>({
    url: Api.PraiseGetAll + params.orderId + '/' + params.createTime,
  });
}
