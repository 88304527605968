import type { AppRouteRecordRaw, Menu } from '/@/router/types';

import { defineStore } from 'pinia';
import { store } from '/@/store';
import { useI18n } from '/@/hooks/web/useI18n';
import { useUserStore } from './user';
import { useAppStoreWithOut } from './app';
import { toRaw } from 'vue';
import { transformObjToRoute, flatMultiLevelRoutes } from '/@/router/helper/routeHelper';
import { transformRouteToMenu } from '/@/router/helper/menuHelper';

import projectSetting from '/@/settings/projectSetting';

import { PermissionModeEnum } from '/@/enums/appEnum';

import { LAYOUT } from '/@/router/constant';
import { ERROR_LOG_ROUTE, PAGE_NOT_FOUND_ROUTE } from '/@/router/routes/basic';

import { filter } from '/@/utils/helper/treeHelper';

import { getMenuList } from '/@/api/sys/menu';

import { useMessage } from '/@/hooks/web/useMessage';
import { PageEnum } from '/@/enums/pageEnum';
import { userMenuTree } from '/@/api/sys/user';
import { UserMenuTreeResultModel } from '/@/api/sys/model/userModel';
import { ExportTaskRoute, routesMap } from '/@/router/routes';
import { dictToOption } from '/@/utils/dict';
import { NavType } from '/#/vue-router';

type AuthPrefix = 'pmf' | 'pmb'; // pmf字段  pmb按钮
type AuthType = 'view' | 'modify' | 'delete' | 'submit'; // 查看/编辑/删除/提交

interface PermissionState {
  // Permission code list
  // 权限代码列表
  permCodeList: string[];
  routePermCodeList: string[];
  // Whether the route has been dynamically added
  // 路由是否动态添加
  isDynamicAddedRoute: boolean;
  // To trigger a menu update
  // 触发菜单更新
  lastBuildMenuTime: number;
  // Backstage menu list
  // 后台菜单列表
  backMenuList: Menu[];
  // 菜单列表
  frontMenuList: Menu[];
  // 菜单path找到对应的路由
  menuMap: Map<string, Menu>;
}

export const usePermissionStore = defineStore({
  id: 'app-permission',
  state: (): PermissionState => ({
    // 权限代码列表
    permCodeList: [],
    // 当前路由下权限代码列表
    routePermCodeList: [],
    // Whether the route has been dynamically added
    // 路由是否动态添加
    isDynamicAddedRoute: false,
    // To trigger a menu update
    // 触发菜单更新
    lastBuildMenuTime: 0,
    // Backstage menu list
    // 后台菜单列表
    backMenuList: [],
    // menu List
    // 菜单列表
    frontMenuList: [],
    // 菜单path找到对应的路由
    menuMap: new Map(),
  }),
  getters: {
    getPermCodeList(): string[] {
      return this.permCodeList;
    },
    getRoutePermCodeList(): string[] {
      return this.routePermCodeList;
    },
    getBackMenuList(): Menu[] {
      return this.backMenuList;
    },
    getFrontMenuList(): Menu[] {
      return this.frontMenuList;
    },
    getLastBuildMenuTime(): number {
      return this.lastBuildMenuTime;
    },
    getIsDynamicAddedRoute(): boolean {
      return this.isDynamicAddedRoute;
    },
    getMenuMap(): Map<string, Menu> {
      return this.menuMap;
    },
  },
  actions: {
    setRoutePermCodeList(codeList: string[]) {
      this.routePermCodeList = codeList;
    },

    setPermCodeList(codeList: string[]) {
      this.permCodeList = codeList;
    },

    setPushPermCodeList(codeList: string[]) {
      this.permCodeList.push(...codeList);
    },

    // 是否存在权限
    hasPermission(code: string, prefix: AuthPrefix = 'pmf', type: AuthType = 'view') {
      const permission = `${prefix}:${type}:${code}`;
      return (
        this.getRoutePermCodeList.includes(permission) || this.getPermCodeList.includes(permission)
      );
    },

    setBackMenuList(list: Menu[]) {
      this.backMenuList = list;
      list?.length > 0 && this.setLastBuildMenuTime();
    },

    setFrontMenuList(list: Menu[]) {
      this.frontMenuList = list;
    },

    setLastBuildMenuTime() {
      this.lastBuildMenuTime = new Date().getTime();
    },

    setDynamicAddedRoute(added: boolean) {
      this.isDynamicAddedRoute = added;
    },
    resetState(): void {
      this.isDynamicAddedRoute = false;
      this.permCodeList = [];
      this.backMenuList = [];
      this.lastBuildMenuTime = 0;
    },
    setMenuMap(path: string, route: Menu) {
      this.menuMap.set(path, route);
    },
    getMenuItem(path = ''): Menu | undefined {
      const q = path.split('/');
      q.splice(q.length - 1, 1);
      const route = this.getMenuMap.get(path);
      return route || this.getMenuMap.get(q.join('/') + '/:id');
    },

    // 构建路由
    async buildRoutesAction(): Promise<AppRouteRecordRaw[]> {
      const findItemById = (
        id: string | number,
        array: { id: string | number; title: string }[],
      ) => {
        for (let i = 0; i < array.length; i++) {
          if (array[i].id == id) {
            return array[i];
          }
        }
        return null; // 如果未找到匹配项，则返回 null 或者其他你希望返回的值
      };
      const modules = import.meta.glob(`../../views/**/index.vue`);
      // 处理接口返回的用户菜单
      const formatMenu = (
        tree: UserMenuTreeResultModel[],
        level = 1,
      ): Promise<AppRouteRecordRaw>[] => {
        return tree
          .filter((item) => {
            if (item.type === 1) {
              this.setPushPermCodeList(item.permissions || []);
            }
            return item.type !== 1;
          })
          .map(async (item) => {
            item.path = item.path || '';
            const option = routesMap.get(item.path);
            const nav: unknown[] = [];
            if (item.permissions) {
              item.permissions.forEach((tab) => {
                const match = tab.match(/:(\d+)/);
                if (match) {
                  const tabNumber = match[1];
                  if (option?.meta?.nav) {
                    nav.push(findItemById(tabNumber, option.meta?.nav));
                  }
                }
              });
            }
            const m: AppRouteRecordRaw = {
              path: item.path || '/' + item.name,
              name: item.name,
              component: level === 1 ? LAYOUT : undefined,
              ...option,
              meta: {
                title: item.name,
                icon: item.icon,
                permissions: item.permissions,
                ...(option?.meta || {}),
                nav: (nav.length > 0 ? nav : option?.meta?.nav) as NavType,
              },
            };
            // 字典
            if (m.meta.dict) {
              const dict = dictToOption(m.meta.dict as string, 'title', 'id');
              m.meta.nav = dict;
            }
            // 接口请求nav
            if (m.meta.api) {
              try {
                const res = await m.meta.api();
                m.meta.nav = res.map((item) => {
                  return {
                    ...item,
                    id: item.dataSource || item.id,
                    title: item.dataSourceName || item.title,
                  };
                });
              } catch (e) {}
            }
            m.meta.realPath = m.path;
            // 组件嵌套了Nav
            if (m.meta.nav && level !== 1) {
              m.path += '/:id';
            }
            // 递归
            if (item.routes) {
              m.children = await Promise.all(formatMenu(item.routes, level + 1));
            }
            // 只有一级（工作台)，统一嵌套一层
            else if (level === 1) {
              const id = m.meta.nav ? '/:id' : '';
              m.meta.realPath = m.meta.affix ? undefined : m.path;
              const name = m.name;
              m.name = `${name}Page`;
              m.redirect = `${item.path}/index${id}`;
              m.children = [
                {
                  path: `index${id}`,
                  name: name,
                  component: modules[`../../views${item.path}/index.vue`],
                  meta: {
                    title: item.name,
                    nav: undefined,
                  },
                },
              ];
            }
            // 最后一级直接引入页面文件
            else {
              m.component = modules[`../../views${item.path}/index.vue`];
            }
            this.setMenuMap(m.path, m);
            return m;
          });
      };
      // 获取用户菜单
      const menuTree = (await userMenuTree()) || [];
      const menu = await Promise.all(formatMenu(menuTree));
      menu.unshift(ExportTaskRoute);
      menu.unshift(PAGE_NOT_FOUND_ROUTE);
      const { t } = useI18n();
      const userStore = useUserStore();
      const appStore = useAppStoreWithOut();
      let routes: AppRouteRecordRaw[] = [];
      const roleList = toRaw(userStore.getRoleList) || [];
      const { permissionMode = projectSetting.permissionMode } = appStore.getProjectConfig;

      // 路由过滤器 在 函数filter 作为回调传入遍历使用
      const routeFilter = (route: AppRouteRecordRaw) => {
        const { meta } = route;
        // 抽出角色
        const { roles } = meta || {};
        if (!roles) return true;
        // 进行角色权限判断
        return roleList.some((role) => roles.includes(role));
      };

      const routeRemoveIgnoreFilter = (route: AppRouteRecordRaw) => {
        const { meta } = route;
        // ignoreRoute 为true 则路由仅用于菜单生成，不会在实际的路由表中出现
        const { ignoreRoute } = meta || {};
        // arr.filter 返回 true 表示该元素通过测试
        return !ignoreRoute;
      };

      /**
       * @description 根据设置的首页path，修正routes中的affix标记（固定首页）
       * */
      const patchHomeAffix = (routes: AppRouteRecordRaw[]) => {
        if (!routes || routes.length === 0) return;
        let homePath: string = userStore.getUserInfo.homePath || PageEnum.BASE_HOME;

        function patcher(routes: AppRouteRecordRaw[], parentPath = '') {
          if (parentPath) parentPath = parentPath + '/';
          routes.forEach((route: AppRouteRecordRaw) => {
            const { path, children, redirect } = route;
            const currentPath = path.startsWith('/') ? path : parentPath + path;
            if (currentPath === homePath) {
              if (redirect) {
                homePath = route.redirect! as string;
              } else {
                route.meta = Object.assign({}, route.meta, { affix: true });
                throw new Error('end');
              }
            }
            children && children.length > 0 && patcher(children, currentPath);
          });
        }

        try {
          patcher(routes);
        } catch (e) {
          // 已处理完毕跳出循环
        }
        return;
      };

      switch (permissionMode) {
        // 角色权限
        case PermissionModeEnum.ROLE:
          // 对非一级路由进行过滤
          routes = filter(menu, routeFilter);
          // 对一级路由根据角色权限过滤
          routes = routes.filter(routeFilter);
          // Convert multi-level routing to level 2 routing
          // 将多级路由转换为 2 级路由
          routes = flatMultiLevelRoutes(routes);
          break;

        // 路由映射， 默认进入该case
        case PermissionModeEnum.ROUTE_MAPPING:
          // 对非一级路由进行过滤
          routes = filter(menu, routeFilter);
          // 对一级路由再次根据角色权限过滤
          routes = routes.filter(routeFilter);
          // 将路由转换成菜单
          const menuList = transformRouteToMenu(routes, true);
          // 移除掉 ignoreRoute: true 的路由 非一级路由
          routes = filter(routes, routeRemoveIgnoreFilter);
          // 移除掉 ignoreRoute: true 的路由 一级路由；
          routes = routes.filter(routeRemoveIgnoreFilter);
          // 对菜单进行排序
          menuList.sort((a, b) => {
            return (a.meta?.orderNo || 0) - (b.meta?.orderNo || 0);
          });

          // 设置菜单列表
          this.setFrontMenuList(menuList);

          // Convert multi-level routing to level 2 routing
          // 将多级路由转换为 2 级路由
          routes = flatMultiLevelRoutes(routes);
          break;

        //  If you are sure that you do not need to do background dynamic permissions, please comment the entire judgment below
        //  如果确定不需要做后台动态权限，请在下方评论整个判断
        case PermissionModeEnum.BACK:
          const { createMessage } = useMessage();

          createMessage.loading({
            content: t('sys.app.menuLoading'),
            duration: 1,
          });

          // !Simulate to obtain permission codes from the background,
          // 模拟从后台获取权限码，
          // this function may only need to be executed once, and the actual project can be put at the right time by itself
          // 这个功能可能只需要执行一次，实际项目可以自己放在合适的时间
          let routeList: AppRouteRecordRaw[] = [];
          try {
            routeList = (await getMenuList()) as AppRouteRecordRaw[];
          } catch (error) {
            console.error(error);
          }

          // Dynamically introduce components
          // 动态引入组件
          routeList = transformObjToRoute(routeList);

          //  Background routing to menu structure
          //  后台路由到菜单结构
          const backMenuList = transformRouteToMenu(routeList);
          this.setBackMenuList(backMenuList);

          // remove meta.ignoreRoute item
          // 删除 meta.ignoreRoute 项
          routeList = filter(routeList, routeRemoveIgnoreFilter);
          routeList = routeList.filter(routeRemoveIgnoreFilter);

          routeList = flatMultiLevelRoutes(routeList);
          routes = [PAGE_NOT_FOUND_ROUTE, ...routeList];
          break;
      }

      routes.push(ERROR_LOG_ROUTE);
      patchHomeAffix(routes);
      return routes;
    },
  },
});

// Need to be used outside the setup
// 需要在设置之外使用
export function usePermissionStoreWithOut() {
  return usePermissionStore(store);
}
